<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <c-card title="위험물저장소 배치도" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn label="LBLADD" v-if="editable" icon="add" @btnClicked="addDanger" />
          <c-btn label="LBLREMOVE" v-if="editable && gridData.length > 0" icon="remove" @btnClicked="removeMapDanger"/>
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="map.maps"
            mappingType="POST"
            label="LBLSAVE" 
            icon="save" 
            @beforeAction="saveMapDanger"
            @btnCallback="saveMapDangerCallback"/>
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <template>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 container" ref="carousel">
            <q-carousel
              v-if="map.maps && map.maps.length > 0"
              :key="carouselKey"
              class="map-carousel"
              control-type="regular"
              control-color="grey-6"
              animated
              arrows
              infinite
              v-model="map.sopMapId"
              @transition="transition"
            >
              <q-carousel-slide 
                v-for="(map, idx) in map.maps"
                :key="idx"
                :name="map.sopMapId" 
                :img-src="map.mapSrc"
                >
                <template>
                  <div class="mapTitleDiv" v-text="map.mapName"></div>
                  <div class="q-img__content absolute-full"> 
                    <template v-if="map.dangers && map.dangers.length > 0">
                      <VueDraggableResizable
                        v-for="(danger, _idx) in map.dangers"
                        :key="`${idx}_${_idx}`"
                        ref="markImage"
                        class="mainMarkImage"
                        :resizable="false"
                        :parent="true"
                        :draggable="true"
                        :x="danger.locationXcoordinate"
                        :y="danger.locationYcoordinate"
                        :w="30" :h="30"
                        :grid="[30, 30]"
                        @dragging="(x, y) => onDrag(danger, x, y)"
                      >
                        <div class="markImage-abbreviation-parent"
                          :style="{ 
                            width: '220px', 
                            left: getLeft(danger),
                            top: '30px',
                          }">
                          <div class="markImage-abbreviation" 
                            @mouseover="() => { danger.isHover = true }"
                            @mouseleave="() => { danger.isHover = false }">
                            <transition name="mark-list" tag="div">
                              <div>
                                <q-banner
                                  dense 
                                  :class="[idx % 2 ===1 ? 'bg-grey-3' : 'bg-grey-1', 'markImage-abbreviation-banner-detail']">
                                  <template v-slot:avatar>
                                    <q-icon name="warning" class="text-red" size='xs' />
                                  </template>
                                  <div class="caption-header">
                                    {{danger.chmDangerArea}}
                                  </div>
                                  <div class="caption-danger">
                                    {{ '구분: ' + danger.chmDangerTypeName }}
                                    <br>
                                    {{ '품명 표기: ' + danger.chmDangerMstName }}
                                  </div>
                                </q-banner>
                              </div>
                            </transition>
                          </div>
                        </div>
                      </VueDraggableResizable>
                    </template>
                  </div>
                </template>
              </q-carousel-slide>
            </q-carousel>
            <el-empty v-else 
              :image-size="435"
              description="등록된 지도가 없습니다."></el-empty>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 container">
            <c-table
              ref="table"
              :isTitle="false"
              :columns="grid.columns"
              :data="gridData"
              selection="multiple"
              rowKey="chmDangerMstId"
              @linkClick="linkClick"
            >
            </c-table>
          </div>
        </template>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
export default {
  name: 'dangerLayout',
  components: { VueDraggableResizable },
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y'
      },
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerTypeName",
            field: "chmDangerTypeName",
            label: "구분",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerArea",
            field: "chmDangerArea",
            label: "설치장소",
            style: 'width:200px',
            align: "left",
            type: "link",
            sortable: true,
          },
          {
            name: "chmDangerMstName",
            field: "chmDangerMstName",
            label: "품명 표기",
            style: 'width:150px',
            align: "left",
            sortable: true,
          },
          {
            name: "chmDangerManageUserName",
            field: "chmDangerManageUserName",
            label: "안전관리자",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerUserName",
            field: "chmDangerUserName",
            style: 'width:150px',
            label: "LBLMANAGER",
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerSafetyUserName",
            field: "chmDangerSafetyUserName",
            style: 'width:150px',
            label: "안전대리자",
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerCount",
            field: "chmDangerCount",
            label: "지정수량 표기",
            style: 'width:100px',
            align: "left",
            sortable: true,
          },
          {
            name: "chmDangerMainUse",
            field: "chmDangerMainUse",
            style: 'width:100px',
            label: "주용도",
            align: "left",
            sortable: true,
          },
        ],
      },
      map: {
        sopMapId: '',
        maps: [],
      },
      carouselKey: '',
      editable: true,
      isSave: false,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '70%',
        isFull: true,
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    mapRate() {
      return 0.835
    },
    gridData() {
      let data = [];
      if (this.map.maps && this.map.maps.length > 0 && this.map.sopMapId) {
        data = this.$_.find(this.map.maps, item => {
          return item.sopMapId === this.map.sopMapId
        }).dangers
      }
      return data
    }
  },
  watch: {
    supWorks: {
      handler: function () {
        this.setItems();
      },
      deep: true,
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.danger.map.list.url;
      this.saveUrl = transactionConfig.chm.danger.map.save.url;
      this.deleteUrl = transactionConfig.chm.danger.map.delete.url;
      // code setting
      let _height = 575 * this.mapRate
      this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
      // list setting
      this.getList();
    },
    getList(sopMapId) {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          this.$set(this.map, 'maps', _result.data);
          this.$set(this.map, 'sopMapId', sopMapId ? sopMapId : this.map.maps && this.map.maps.length > 0 ? this.map.maps[0].sopMapId : '');
          this.$refs['carousel'].style.setProperty("--map-ratio", this.map.maps[0].ratio);

          this.carouselKey = uid();
        } else {
          Object.assign(this.$data.map, this.$options.data().map);
        }
      },);
    },
    linkClick(row) {
      this.popupOptions.title = "위험물저장소 상세"; // 위험물저장소 상세
      this.popupOptions.param = {
        chmDangerMstId: row ? row.chmDangerMstId : '',
      };
      this.popupOptions.target = () => import(`${"./dangerMasterDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList(this.map.sopMapId);
    },
    transition() {   
      // this.grid.data = this.map.maps[this.map.slide].mapDetails;
    },
    onDrag(item, x, y) {
      this.$set(item, 'locationXcoordinate', x)
      this.$set(item, 'locationYcoordinate', y)
      if (item.editFlag !== 'C') {
        this.$set(item, 'editFlag', 'U')
        this.$set(item, 'chgUserId', this.$store.getters.user.userId)
      }
    },
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationXcoordinate + 210;
      let detailLocation = item.locationXcoordinate + 210 
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > 800) {
        left = 800 - calLocation
      }
      return left + 'px'
    },
    addDanger() {
      this.popupOptions.title = '위험물저장소'; // 위험물저장소
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'./dangerMasterPop'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDangerPopup;
    },
    closeDangerPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let map = this.$_.find(this.map.maps, { sopMapId: this.map.sopMapId });
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(map.dangers, { chmDangerMstId: item.chmDangerMstId }) === -1) {
            map.dangers.push({
              chmDangerMapId: '',  // 위험물 저장소 배치도 번호
              sopMapId: this.map.sopMapId,  // 지도 일련번호
              chmDangerMstId: item.chmDangerMstId,  // 위험물 저장소 일련번호
              locationXcoordinate: 0,  // 작업위치 X좌표
              locationYcoordinate: 0,  // 작업위치 Y좌표
              plantName: item.plantName,
              chmDangerTypeName: item.chmDangerTypeName,
              chmDangerArea: item.chmDangerArea,
              chmDangerMstName: item.chmDangerMstName,
              chmDangerManageUserName: item.chmDangerManageUserName,
              chmDangerUserName: item.chmDangerUserName,
              chmDangerSafetyUserName: item.chmDangerSafetyUserName,
              chmDangerCount: item.chmDangerCount,
              chmDangerMainUse: item.chmDangerMainUse,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        })
      }
    },
    saveMapDanger() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveMapDangerCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList(this.map.sopMapId);
    },
    removeMapDanger() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            let map = this.$_.find(this.map.maps, { sopMapId: this.map.sopMapId });
            this.$_.forEach(selectData, item => {
              map.dangers = this.$_.reject(map.dangers, { chmDangerMstId: item.chmDangerMstId })
            })
            this.$refs['table'].$refs['compo-table'].clearSelection();
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
  }
};
</script>
<style lang="sass">
.markImage
  padding: 0px !important
  text-align: center
  font-size: 20px

.q-img__content
  .markImage.active
    border-width: 2px !important
    border-style: dashed !important
    border-color: hsla(0, 95%, 35%, 1) !important
.q-img__content > div
    background: rgba(0,0,0,0) 

.mainMarkImage
  padding: 0px !important
  text-align: center
  font-size: 20px
  // height: 30px !important
  position: static
  padding: 16px
  color: blue
  // background: rgba(0,0,0,0)
  .markImage-abbreviation-parent
    // width:240px
    text-align: start
    position: absolute
    .markImage-abbreviation
      // cursor: pointer
      display: inline-block
      // min-width:60px
      // max-width:250px
      width: auto
      border-left: 1px solid #757575
      border-right: 1px solid #757575
      border-top: 1px solid #757575
      border-bottom: 1px solid #757575
      .markImage-abbreviation-banner
        border-right: 1px solid #757575
        display: inline-block
        text-align: center
        min-width:60px
        i
          font-size: 25px
      .markImage-abbreviation-banner.last-banner
        border-right: 0
      .markImage-abbreviation-plus
        display: inline-block
        text-align: center
        min-width:40px
      .markImage-abbreviation-banner-detail
        padding: 1px !important
.caption-danger
  font-size: 0.7em
  font-style: italic
  white-space: normal
  color: #555

.caption-header
  font-size: 0.8em
  font-style: italic
  white-space: normal
  color: #555

.map-carousel
  height: var(--map-height) !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__slide
    width: calc(var(--map-ratio) * var(--map-height)) !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__arrow
    transition: all 0.5s
    opacity: 0.2
  .q-carousel__arrow:hover
    transition: all 0.5s
    opacity: 0.8
  .q-carousel__slides-container
    .q-panel.scroll
      overflow: hidden !important

</style>

<style scoped>

.mapTitleDiv {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background-color: #283046 !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 2000;
  font-size: 12px;
}
</style>